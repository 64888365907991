// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Lingui from "../../locales/Lingui.re.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as NotFound from "../pages/NotFound.re.mjs";
import * as ReactIntl from "react-intl";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as React$1 from "@lingui/react";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as AppContext from "../layouts/appContext";

var make = AppContext.LocaleContext.Provider;

var Provider = {
  make: make
};

var context = AppContext.LocaleContext;

var LocaleContext = {
  Provider: Provider,
  context: context
};

function LangProvider(props) {
  var data = ReactRouterDom.useLoaderData();
  var match = data.lang;
  var locale;
  switch (match) {
    case "en" :
        locale = "en";
        break;
    case "ja" :
        locale = "ja";
        break;
    default:
      locale = undefined;
  }
  return JsxRuntime.jsx(make, {
              children: JsxRuntime.jsx(React$1.I18nProvider, {
                    i18n: Lingui.i18n,
                    children: locale !== undefined ? JsxRuntime.jsx(ReactIntl.IntlProvider, {
                            locale: locale,
                            timeZone: data.timezone,
                            children: JsxRuntime.jsx(ReactRouterDom.Outlet, {})
                          }) : JsxRuntime.jsx(NotFound.make, {})
                  }),
              value: data
            });
}

function make$1(props) {
  var locale = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(props);
  newrecord.to = props.to.startsWith("/") ? "/" + locale.lang + props.to : props.to;
  return JsxRuntime.jsx(ReactRouterDom.Link, newrecord);
}

var Link = {
  make: make$1
};

function make$2(props) {
  var locale = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(props);
  newrecord.to = props.to.startsWith("/") ? "/" + locale.lang + props.to : props.to;
  return JsxRuntime.jsx(ReactRouterDom.NavLink, newrecord);
}

var NavLink = {
  make: make$2
};

function make$3(props) {
  var locale = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(props);
  var newrecord$1 = Caml_obj.obj_dup(props.to);
  newrecord$1.pathname = Core__Option.map(props.to.pathname, (function (pathname) {
          if (pathname.startsWith("/")) {
            return "/" + locale.lang + pathname;
          } else {
            return pathname;
          }
        }));
  newrecord.to = newrecord$1;
  return JsxRuntime.jsx(ReactRouterDom.Link, newrecord);
}

var LinkWithOpts = {
  make: make$3
};

var Router = {
  Link: Link,
  NavLink: NavLink,
  LinkWithOpts: LinkWithOpts
};

var make$4 = LangProvider;

export {
  LocaleContext ,
  make$4 as make,
  Router ,
}
/* make Not a pure module */
